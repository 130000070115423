export default function App() {
  return (
    <div className="body-content text-center">
      <div className="text-white">
        <h1>Workhorse Detroit</h1>
        <p>Coming soon!</p>
      </div>
    </div>
  );
}
